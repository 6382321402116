<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-responsive min-height="350">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="project in computedProjects1"
                :key="project.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/r21/${project.img}`"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      hover
                      @click="overlay = project.img"
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <v-icon large>
                            mdi-magnify
                          </v-icon>
                        </v-overlay>
                      </v-fade-transition>
                      <v-slide-y-reverse-transition>
                        <v-footer
                          v-if="hover"
                          absolute
                          class="justify-center"
                          height="75"
                        >
                          <div class="heading">
                            {{ project.name }}
                          </div>
                        </v-footer>
                      </v-slide-y-reverse-transition>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>

        <v-fade-transition mode="out-in">
          <v-overlay
            v-if="overlay"
            fixed
            opacity=".9"
          >
            <v-btn
              color="transparent"
              depressed
              fab
              fixed
              large
              right
              top
              @click="overlay = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>

            <v-img
              :src="`/static/r21/${overlay}`"
              width="800"
              max-width="90vw"
            />
          </v-overlay>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
      >
        <base-subheading>
          <h1
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            Mieszkanie 36m² w zrewitalizowanej kamienicy w Poznaniu
          </h1>
        </base-subheading>
        <p class="text-justify">
          Realizacja wraz z projektem wnętrza.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      category: null,
      overlay: false,
      projects: [
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-0.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-1.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-2.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-3.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-4.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-5.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-6.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-7.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-8.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-9.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-10.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-11.jpg',
          'categories': [1]
        },
        {
          'name': 'mieszkanie 36m², ul. Różana 21, Poznań',
          'img': 'r21-12.png',
          'categories': [1]
        }
      ]
    }),

    computed: {
      computedProjects1 () {
        return !this.category
          ? this.projects
          : this.projects.filter(p => p.categories.includes(this.category))
      }
    }
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
